import { useMemo } from "react";
import { useLocation } from "@reach/router";
import querystring from "query-string";

export default function useLocationSearch() {
  const { search } = useLocation();
  const { parse } = querystring;

  const parsedSearch = useMemo(
    () => parse(search, { parseBooleans: true, parseNumbers: true }),
    [search]
  );
  return parsedSearch;
}
