import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

import Loading from "@components/Loading";
import LoadingData from "@components/LoadingData";

const WrapPageElementLayout = ({ children }) => {
  const { ready } = useI18next();

  return (
    <>
      {!ready && <LoadingData className="loading-spin-full" />}
      {children}
      <Loading />
    </>
  );
};
export default WrapPageElementLayout;
