import { convertToHalfWidth } from "./stringHelper";
import fontLib from "@fonts/tag/_font.json";
import kern from "@fonts/tag/_kern.json";
import kern5 from "@fonts/tag/_kern+5.json";
import kern8 from "@fonts/tag/_kern+8.json";
import kern10 from "@fonts/tag/_kern+10.json";
import kern12 from "@fonts/tag/_kern+12.json";
import kern15 from "@fonts/tag/_kern+15.json";
import offsetLib from "@fonts/tag/_offset.json";

const getPosition = (targets, kernJSON) => {
  let prev = "";
  let step = [];
  let offset = [];

  step[0] = 0;

  for (let i = 0; i < targets.length; i++) {
    let next = targets[i];

    if (/^[0-9]+$/i.test(next)) {
      offset[i] = offsetLib[next];
      step[i] -= offset[i];
    } else {
      offset[i] = 0;
    }

    if (prev !== "") {
      let move =
        kernJSON === "kern"
          ? kern[prev + next]
          : kernJSON === "kern5"
          ? kern5[prev + next]
          : kernJSON === "kern8"
          ? kern8[prev + next]
          : kernJSON === "kern10"
          ? kern10[prev + next]
          : kernJSON === "kern12"
          ? kern12[prev + next]
          : kern15[prev + next];
      step[i] = move;
    }
    prev = next;
  }

  return { step, offset };
};

const TagFontHelper = (string, fontSize, kernJSON) => {
  let tagFont = {
    content: [],
    maxLength: 0,
  };

  if (string) {
    const array = convertToHalfWidth(string).split("");
    const position = getPosition(string, kernJSON);
    const K = (fontSize / 841) * fontLib["A"].scale;
    let result = [];
    let positionX = 0;
    let maxLength = 0;

    array.forEach((item, i) => {
      positionX += position.step[i] * K;
      let positionLeft: number = positionX + position.offset[i] * K;

      if (fontLib[item]) {
        result.push({
          image: `/char-images/${fontLib[item].code}.svg`,
          name: item,
          style: {
            position: "absolute",
            top: 0,
            left: 0,
            transform: `translateX(${positionLeft.toFixed(2)}px)`,
            height: `${fontSize}px`,
          },
          length: Math.ceil(
            positionLeft + (fontLib[item].width * fontSize) / 841
          )
        });
        maxLength = Math.ceil(
          positionLeft + (fontLib[item].width * fontSize) / 841
        );
      } else {
        result.push({ invalid: item });
      }
    });

    tagFont = {
      content: result,
      maxLength,
    };
  } else {
    tagFont = {
      content: [],
      maxLength: 0,
    };
  }

  return tagFont;
};

export default TagFontHelper;
