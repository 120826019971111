import { createModel } from "@rematch/core";
import { RootModel } from "./";

export const option = createModel<RootModel>()({
  state: {
    disabled: false,
  },
  reducers: {
    setOptionDisabled(state, payload: boolean) {
      state.disabled = payload;
      return state;
    },
  },
});
