import { createModel } from "@rematch/core";
import { RootModel } from "./";

const initLoading = {
  isLoading: false,
  isFinished: false,
  duration: 4.3,
  isFetchingDrinksAPI: true,
  isStepsBarSwiping: false,
  drinkGifBurstTime: new Date().getTime(),
  selectGifBurstTime: new Date().getTime(),
};

export const loading = createModel<RootModel>()({
  state: initLoading,
  reducers: {
    setIsLoading(state, payload: boolean) {
      state.isLoading = payload;
      return state;
    },
    setIsFinished(state, payload: boolean) {
      state.isFinished = payload;
      return state;
    },
    setIsFetchingDrinksAPI(state, payload: boolean) {
      state.isFetchingDrinksAPI = payload;
      return state;
    },
    setIsStepsBarSwiping(state, payload: boolean) {
      state.isStepsBarSwiping = payload;
      return state;
    },
    setDrinkGifBurstTime(state) {
      state.drinkGifBurstTime = new Date().getTime();
      return state;
    },
    setSelectGifBurstTime(state) {
      state.selectGifBurstTime = new Date().getTime();
      return state;
    },
    resetLoading(state) {
      return { ...state, ...initLoading };
    },
  },
  effects: (dispatch) => ({
    resetData() {
      dispatch.loading.setIsLoading(false);
      dispatch.loading.setIsFinished(false);
    },
  }),
});
