import { createModel } from "@rematch/core";
import { RootModel } from "./";
import {
  emptyPlaceholder,
  labelFontSize,
  textDefaultValues,
  kernJSON,
  textMaxLength,
} from "@constants/global";
import TagFontHelper from "@utils/TagFontHelper";

export const tagFont = createModel<RootModel>()({
  state: {
    A: {},
    B: {},
  },
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setText(state, { name, value, labelType }) {
      state[labelType][name] = value;

      return state;
    },
  },
  effects: (dispatch) => ({
    setTagFontData({ drinkData }) {
      const fontData = {};

      ["A", "B"].forEach((item) => {
        fontData[item] = {
          text1: TagFontHelper(
            drinkData.text1 || textDefaultValues.text1,
            labelFontSize[item].text1,
            kernJSON[item].text1
          ),
          text2: TagFontHelper(
            drinkData.text2 || textDefaultValues.text2,
            labelFontSize[item].text2,
            kernJSON[item].text2
          ),
          time: TagFontHelper(
            drinkData.createdAt,
            labelFontSize[item].time,
            kernJSON[item].time
          ),
          shop: TagFontHelper(
            drinkData.shop?.name,
            labelFontSize[item].shop,
            kernJSON[item].shop
          ),
          drink: TagFontHelper(
            drinkData.drink?.value || emptyPlaceholder.halfWidth,
            labelFontSize[item].drinkOption,
            kernJSON[item].drinkOption
          ),
          espresso: TagFontHelper(
            drinkData.espresso?.value || emptyPlaceholder.halfWidth,
            labelFontSize[item].drinkOption,
            kernJSON[item].drinkOption
          ),
          hotCold: TagFontHelper(
            drinkData.hotCold?.value || emptyPlaceholder.halfWidth,
            labelFontSize[item].drinkOption,
            kernJSON[item].drinkOption
          ),
          sweet: TagFontHelper(
            drinkData.sweet?.value || emptyPlaceholder.halfWidth,
            labelFontSize[item].filter,
            kernJSON[item].filter
          ),
          option: TagFontHelper(
            drinkData.option?.value || emptyPlaceholder.halfWidth,
            labelFontSize[item].filter,
            kernJSON[item].filter
          ),
          orderId: TagFontHelper(
            drinkData.orderId,
            labelFontSize[item].orderId,
            kernJSON[item].orderId
          ),
        };
      });

      this.setState(fontData);
    },
    setTextTagFont({ name, value, labelType }) {
      const inputTagFont = TagFontHelper(
        value || textDefaultValues[name],
        labelFontSize[labelType][name],
        kernJSON[labelType][name]
      );
      const invalidLetters = inputTagFont.content.filter(
        (item) => item.invalid
      );

      if (invalidLetters?.length) {
        const firstInvalidIndex = inputTagFont.content.findIndex(
          (item) => item.invalid
        );
        const firstOverLengthIndex = inputTagFont.content
          .slice(0, firstInvalidIndex)
          .findIndex((item) => item.length > textMaxLength);
        dispatch.drink.setLabelValue({
          [name]: value.substring(
            0,
            firstOverLengthIndex !== -1
              ? firstOverLengthIndex
              : firstInvalidIndex
          ),
        });
        dispatch.input.setValidationError({ [name]: "notMatchRule" });
      } else if (inputTagFont?.maxLength > textMaxLength) {
        const firstOverLengthIndex = inputTagFont.content.findIndex(
          (item) => item.length > textMaxLength
        );
        dispatch.drink.setLabelValue({
          [name]: value.substring(0, firstOverLengthIndex),
        });
        dispatch.input.setValidationError({ [name]: "overMaxLength" });
      } else {
        dispatch.input.setValidationError({ [name]: null });
        dispatch.drink.setLabelValue({ [name]: value });
      }
    },
    resetAllTextTagFont({ labelType }, { input }) {
      ["text1", "text2"].forEach((name) => {
        const textValue = input?.value?.[name];
        const inputTagFont = TagFontHelper(
          textValue || textDefaultValues[name],
          labelFontSize[labelType][name],
          kernJSON[labelType][name]
        );
        const invalidLetters = inputTagFont.content.filter(
          (item) => item.invalid
        );

        if (invalidLetters?.length) {
          const firstInvalidIndex = inputTagFont.content.findIndex(
            (item) => item.invalid
          );
          const firstOverLengthIndex = inputTagFont.content
            .slice(0, firstInvalidIndex)
            .findIndex((item) => item.length > textMaxLength);
          dispatch.drink.setLabelValue({
            [name]: textValue.substring(
              0,
              firstOverLengthIndex !== -1
                ? firstOverLengthIndex
                : firstInvalidIndex
            ),
          });
          dispatch.input.setValidationError({ [name]: "notMatchRule" });
        } else if (inputTagFont?.maxLength > textMaxLength) {
          const firstOverLengthIndex = inputTagFont.content.findIndex(
            (item) => item.length > textMaxLength
          );
          dispatch.drink.setLabelValue({
            [name]: textValue.substring(0, firstOverLengthIndex),
          });
          dispatch.input.setValidationError({ [name]: "overMaxLength" });
        } else {
          dispatch.input.setValidationError({ [name]: null });
          dispatch.drink.setLabelValue({ [name]: textValue });
        }
      });
    },
  }),
});
