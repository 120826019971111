import React from "react";

import loading from "@images/loading/loading.svg";

import "./index.scss";

const LoadingData = ({ className = "" }) => (
  <div className={`loading-spin ${className}`}>
    <img
      className="loading-icon"
      width={121}
      height={121}
      src={loading}
      alt="loading"
    />
  </div>
);

export default LoadingData;
