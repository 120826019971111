export const chunk = (arr, count) => {
  const cloneArr = [...arr];
  const result = [];
  while (cloneArr.length) {
    result.push(cloneArr.splice(0, count));
  }
  return result;
};

export const diff = (array, array2) => {
  return array.concat(array2).filter(function (v, _, arr) {
    return arr.indexOf(v) === arr.lastIndexOf(v);
  });
};
