import { createModel } from "@rematch/core";

import { chunk } from "@utils/arrayHelper";
import { bannerPageSize } from "@constants/global";
import { getWindowDimensions } from "@hooks/useWindowDimensions";
import { sortByPriority } from "./filter";

const filterDataByCondition = (data, condition, array, isPickup) => {
  if (condition[array[0]]) {
    const id = condition[array[0]].id;
    let filteredData = data.filter((item) =>
      id === 0
        ? item
        : (item[array[0] + "Filters"] || []).filter((itemId) => itemId === id)
            .length > 0
    );
    if (array[0] === "color") {
      filteredData = sortByPriority(
        isPickup
          ? filteredData.filter((item) => {
              const currentTime = new Date().getTime();
              const pickupDisplayFromTime =
                !!item.pickupDisplayFrom &&
                Date.parse(item.pickupDisplayFrom.replace(/-/g, "/"));
              const pickupDisplayToTime =
                !!item.pickupDisplayTo &&
                Date.parse(item.pickupDisplayTo.replace(/-/g, "/"));
              return (
                (!pickupDisplayFromTime && !pickupDisplayToTime) ||
                (!pickupDisplayFromTime && currentTime < pickupDisplayToTime) ||
                (!pickupDisplayToTime && currentTime > pickupDisplayFromTime) ||
                (currentTime > pickupDisplayFromTime &&
                  currentTime < pickupDisplayToTime)
              );
            })
          : filteredData,
        condition?.["categoryType"]?.id
      );
    }

    return filterDataByCondition(
      filteredData,
      condition,
      array.slice(1, array.length),
      isPickup
    );
  } else {
    return data;
  }
};

const initBannerData = {
  data: [],
  filteredData: [],
  filteredDataLength: 0,
  dataLength: 0,
  selectedBanner: null,
  isLoading: false,
  loadedPageNumber: 1,
  displayData: [],
};

export const banner = createModel()({
  state: initBannerData,
  reducers: {
    setData(state, payload) {
      state.data = payload;
      state.dataLength = payload.length;

      return state;
    },
    setFilteredData(state, payload) {
      const array = ["categoryType", "color"];
      const data = filterDataByCondition(
        state.data,
        payload,
        array,
        ["PICKUP", "RECOMMENDED"].includes(payload["categoryType"]?.name)
      );
      const filteredData = data.filter((item) => item !== undefined);
      const windowDimensions = getWindowDimensions();
      const chunkCount =
        windowDimensions.height <= 610
          ? 2
          : windowDimensions.height >= 700
          ? 4
          : 3;

      state.filteredData = chunk(filteredData, chunkCount);
      state.filteredDataLength = filteredData.length;
      state.loadedPageNumber = 1;
      state.displayData = chunk(filteredData, chunkCount).slice(
        0,
        bannerPageSize
      );

      return state;
    },
    setSelectedBanner(state, payload) {
      state.selectedBanner = payload;

      return state;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;

      return state;
    },
    setDisplayData(state) {
      state.loadedPageNumber += 1;
      state.displayData = [
        ...state.displayData,
        ...state.filteredData.slice(
          (state.loadedPageNumber - 1) * bannerPageSize,
          state.loadedPageNumber * bannerPageSize
        ),
      ];

      return state;
    },
    resetData(state) {
      return { ...state, ...initBannerData };
    },
  },
  effects: (dispatch) => ({
    loadingData(_, state) {
      if (state.banner.displayData.length < state.banner.filteredData.length) {
        dispatch.banner.setDisplayData();
      }
    },
  }),
});
