import { createModel } from "@rematch/core";

import { RootModel } from "./";

export const top = createModel<RootModel>()({
  state: {
    faq: { status: "" },
    shopId: null,
    isInShopMode: false,
    isDetectShopModeDone: false,
    isFooterFixed: false,
    isFooterPCFixed: false,
    isStoreSelectionRendered: false,
    isDisabledMenu: false,
  },
  reducers: {
    setFaq(state, payload) {
      state.faq = payload;
      return state;
    },
    setShopId(state, payload) {
      state.shopId = payload;
      return state;
    },
    setIsInShopMode(state, payload) {
      state.isInShopMode = payload;
      return state;
    },
    setIsDetectShopModeDone(state, payload) {
      state.isDetectShopModeDone = payload;
      return state;
    },
    setIsFooterFixed(state, payload) {
      state.isFooterFixed = payload;
      return state;
    },
    setIsStoreSelectionRendered(state, payload) {
      state.isStoreSelectionRendered = payload;
      return state;
    },
    setIsFooterPCFixed(state, payload) {
      state.isFooterPCFixed = payload;
      return state;
    },
    setIsDisabledMenu(state, payload) {
      state.isDisabledMenu = payload;
      return state;
    },
  },
});
