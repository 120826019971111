import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans } from "gatsby-plugin-react-i18next";

import originalImg from "@images/top/drink-menu/original.svg";
import regularImg from "@images/top/drink-menu/regular.svg";
import peachImg from "@images/top/drink-menu/peach.svg";
import lemonImg from "@images/top/drink-menu/lemon.svg";
import earlGreyImg from "@images/top/drink-menu/earl-grey.svg";
import royalBlendImg from "@images/top/drink-menu/royal-blend.svg";
import lightImg from "@images/top/drink-menu/light.svg";

export const steps = [
  {
    id: 1,
    name: "SELECT",
    type: null,
    value: "select",
    isCustomizeDrink: true,
    options: [],
    isHidden: false,
  },
  {
    id: 2,
    name: "DRINK",
    type: null,
    value: "drink",
    isCustomizeDrink: true,
    options: [],
    isHidden: false,
  },
  {
    id: 3,
    type: "customizeDrink",
    name: "BASE",
    value: "espresso",
    isCustomizeDrink: true,
    options: [],
  },
  {
    id: 4,
    type: "customizeDrink",
    name: "COLD/HOT",
    value: "hotCold",
    isCustomizeDrink: true,
    options: [],
  },
  {
    id: 5,
    type: "customizeDrink",
    name: "SWEET",
    value: "sweet",
    isCustomizeDrink: true,
    options: [],
  },
  {
    id: 6,
    type: "customizeDrink",
    name: "FLAVOR",
    value: "option",
    isCustomizeDrink: true,
    options: [],
  },
  {
    id: 7,
    name: null,
    type: "customizeLabel",
    value: null,
    steps: [
      {
        id: 8,
        name: "LABEL",
        value: "label",
        options: [],
      },
      { id: 9, name: "BANNER", value: "banner" },
      { id: 10, name: "TEXT", value: "text" },
    ],
  },
  {
    id: 11,
    name: "FINISH",
    type: null,
    value: "confirm",
    options: null,
  },
];

export const textDefaultValues = {
  text1: "Have a Good Day!",
  text2: "YOUR NAME",
};

export const emptyPlaceholder = {
  halfWidth: "---",
};

export const allPlaceholder = {
  halfWidth: "ALL",
};

export const shopNameDefault = {
  previewLabel: "AT TAG-COFFEE STAN(D)",
  orderLabel: "ALL STORES",
};

export const labelFontSize = {
  A: {
    text1: 31,
    text2: 67,
    time: 34,
    shop: 15,
    drinkOption: 29,
    filter: 20,
    orderId: 14,
  },
  B: {
    text2: 67,
    text1: 34,
    time: 24,
    drinkOption: 24,
    filter: 19,
    shop: 15,
    orderId: 17,
  },
};

export const kernJSON = {
  A: {
    text1: "kern",
    text2: "kern",
    time: "kern12",
    shop: "kern10",
    drinkOption: "kern5",
    filter: "kern10",
    orderId: "kern15",
  },
  B: {
    text1: "kern",
    text2: "kern",
    time: "kern12",
    shop: "kern10",
    drinkOption: "kern8",
    filter: "kern10",
    orderId: "kern15",
  },
};

export const textMaxLength = 290;

export const animation = {
  drinks: {
    data: [
      {
        value: "BLACK",
        animationRunningMilliseconds: 1500,
      },
      {
        value: "LATTE",
        animationRunningMilliseconds: 1500,
      },
    ],
  },
  hotCold: {
    data: [
      {
        value: "COLD",
        animationRunningMilliseconds: 1000,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "HOT",
        animationRunningMilliseconds: 1000,
        stepTranslationMilliseconds: 500,
      },
    ],
  },
  espresso: {
    data: [
      {
        value: "ORIGINAL",
        animationRunningMilliseconds: 0,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "STRONG",
        animationRunningMilliseconds: 0,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "LIGHT",
        animationRunningMilliseconds: 0,
        stepTranslationMilliseconds: 500,
      },
    ],
  },
  option: {
    data: [
      {
        value: "NONE",
        animationRunningMilliseconds: 0,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "MINT",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "CHOCOLATE",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "CARAMEL",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "PASSION FRUIT",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "VANILLA",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "STRAWBERRY",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
      {
        value: "HAZELNUT CHOCOLAT",
        animationRunningMilliseconds: 1500,
        stepTranslationMilliseconds: 500,
      },
    ],
  },
  labelTemplate: {
    milliseconds: 2000,
  },
};

const hotColdOptionKeys = {
  coldBlack: "cold-black",
  coldLatte: "cold-latte",
  coldBlackTea: "cold-blacktea",
  coldFruitTea: "cold-fruittea",
  coldTeaLatte: "cold-tealatte",
  coldTeaLatteBerry: "drink-tea-latte-berry",
  coldTeaPulp: "cold-pulptea",
  hot: "hot",
};

const espressoOptionKeys = {
  drinkBlackLight: "drink-black-light",
  drinkTeaLatteBerry: "drink-tea-latte-berry",
  drinkTeaPulpMangoMix: "drink-tea-pulp-mangomix",
};

export const hotColdBackgroundImage = {
  [hotColdOptionKeys.hot]: "/bottle/hot-bg.png",
  [hotColdOptionKeys.coldBlack]: "/bottle/cold-bg.png",
  [hotColdOptionKeys.coldLatte]: "/bottle/cold-bg.png",
  [hotColdOptionKeys.coldBlackTea]: "/bottle/cold-bg.png",
  [hotColdOptionKeys.coldFruitTea]: "/bottle/cold-bg.png",
  [hotColdOptionKeys.coldTeaLatte]: "/bottle/cold-bg.png",
  [hotColdOptionKeys.coldTeaPulp]: "/bottle/cold-bg.png",
};

export const hotColdAnimationImages = {
  [hotColdOptionKeys.coldBlack]: ["/bottle/cold-black.png"],
  [hotColdOptionKeys.coldLatte]: ["/bottle/cold-latte.png"],
  [hotColdOptionKeys.coldBlackTea]: ["/bottle/cold-blacktea.png"],
  [hotColdOptionKeys.coldFruitTea]: ["/bottle/cold-fruittea.png"],
  [hotColdOptionKeys.coldTeaLatte]: ["/bottle/cold-tealatte.png"],
  [hotColdOptionKeys.coldTeaLatteBerry]: ["/bottle/cold-tealatte-berry.png"],
  [hotColdOptionKeys.coldTeaPulp]: ["/bottle/cold-pulptea.png"],
};

export const espressoAnimationImages = {
  [espressoOptionKeys.drinkBlackLight]: "/bottle/drink-black-light.png",
  [espressoOptionKeys.drinkTeaLatteBerry]: "/bottle/drink-tea-latte-berry.png",
  [espressoOptionKeys.drinkTeaPulpMangoMix]:
    "/bottle/drink-tea-pulp-mangomix.png",
};

export const keyMapping = {
  select: [
    {
      localKey: "animationRunningMilliseconds",
      staticValue: 1500,
    },
  ],
  drink: [
    {
      localKey: "drinkAnimationImage",
      apiKey: "animation",
    },
    {
      localKey: "animationRunningMilliseconds",
      staticValue: 1500,
    },
  ],
  hotCold: [
    {
      localKey: "animationRunningMilliseconds",
      staticValue: 1000,
    },
    {
      localKey: "stepTranslationMilliseconds",
      staticValue: 500,
    },
  ],
  espresso: [
    {
      localKey: "backgroundImage",
      apiKey: "imageUrl",
    },
    {
      localKey: "animationRunningMilliseconds",
      staticValue: 1000,
    },
    {
      localKey: "stepTranslationMilliseconds",
      staticValue: 500,
    },
  ],
  sweet: [
    {
      localKey: "animationRunningMilliseconds",
      staticValue: 0,
    },
    {
      localKey: "stepTranslationMilliseconds",
      staticValue: 500,
    },
  ],
  option: [
    {
      localKey: "descriptionImage",
      apiKey: "imageUrl",
    },
    {
      localKey: "animationRunningMilliseconds",
      staticValue: 1500,
    },
    {
      localKey: "stepTranslationMilliseconds",
      staticValue: 500,
    },
  ],
};

export const labelTemplateName = {
  A: "S",
  B: "W",
};

export const bannerPageSize = 25;

export const DRINK_CATEGORIES = [
  { label: "コーヒー", value: "coffee", animation: "/bottle/drink-black.gif" },
  { label: "紅茶", value: "tea", animation: "/bottle/drink-blacktea.gif" },
];

export const DRINK_MENU_DATA = {
  drinks: [
    {
      title: <Trans i18nKey="pages.drinkMenu.coffee.title" />,
      options: [
        {
          name: "BLACK",
          price: 550,
          image: (
            <StaticImage
              src="../images/top/drink-menu/black-bottle.jpg"
              alt="BLACK"
              placeholder="blurred"
            />
          ),
          flavors: [
            {
              image: regularImg,
              alt: "original",
            },
            {
              image: lightImg,
              alt: "light",
            },
          ],
          description: <Trans i18nKey="pages.drinkMenu.coffee.description1" />,
        },
        {
          name: "LATTE",
          price: 650,
          image: (
            <StaticImage
              src="../images/top/drink-menu/latte-bottle.jpg"
              alt="BLACK"
            />
          ),
          flavors: [
            {
              image: regularImg,
              alt: "original",
            },
          ],
          description: <Trans i18nKey="pages.drinkMenu.coffee.description2" />,
        },
      ],
    },
    {
      title: <Trans i18nKey="pages.drinkMenu.tea.title" />,
      options: [
        {
          name: "BLACK TEA",
          price: 550,
          image: (
            <StaticImage
              src="../images/top/drink-menu/blacktea-bottle.jpg"
              alt="BLACK"
              placeholder="blurred"
            />
          ),
          flavors: [
            {
              image: originalImg,
              alt: "original",
            },
            {
              image: earlGreyImg,
              alt: "earl grey",
            },
          ],
          description: <Trans i18nKey="pages.drinkMenu.tea.description1" />,
        },
        {
          name: "FRUIT TEA",
          price: 600,
          image: (
            <StaticImage
              src="../images/top/drink-menu/fruittea-bottle.jpg"
              alt="BLACK"
              placeholder="blurred"
            />
          ),
          flavors: [
            {
              image: lemonImg,
              alt: "lemon",
            },
            {
              image: peachImg,
              alt: "peach",
            },
          ],
          description: <Trans i18nKey="pages.drinkMenu.tea.description2" />,
        },
        {
          name: "TEA LATTE",
          price: 650,
          image: (
            <StaticImage
              src="../images/top/drink-menu/tealatte-bottle.jpg"
              alt="BLACK"
            />
          ),
          flavors: [
            {
              image: royalBlendImg,
              alt: "royal blend",
            },
          ],
          description: <Trans i18nKey="pages.drinkMenu.tea.description3" />,
        },
      ],
    },
  ],
  customizes: {
    title: <Trans i18nKey="pages.drinkMenu.customize.title" />,
    options: [
      {
        title: <Trans i18nKey="pages.drinkMenu.customize.coldHot.title" />,
        description: (
          <Trans i18nKey="pages.drinkMenu.customize.coldHot.description" />
        ),
      },
      {
        title: <Trans i18nKey="pages.drinkMenu.customize.sweet.title" />,
        description: (
          <Trans i18nKey="pages.drinkMenu.customize.sweet.description" />
        ),
      },
      {
        title: <Trans i18nKey="pages.drinkMenu.customize.flavor.title" />,
        description: (
          <Trans i18nKey="pages.drinkMenu.customize.flavor.description" />
        ),
      },
    ],
  },
};

export const LIMITED_DESIGNS_DATA = [
  {
    subTitle: <Trans i18nKey="pages.limitedDesigns.subTitleBanner" />,
    noDataMsg: <Trans i18nKey="pages.limitedDesigns.noDataMsgBanner" />,
    designs: [],
  },
  {
    subTitle: <Trans i18nKey="pages.limitedDesigns.subTitleLabel" />,
    noDataMsg: <Trans i18nKey="pages.limitedDesigns.noDataMsgLabel" />,
    designs: [],
  },
];

export const LANGUAGES = {
  jp: "ja",
  us: "en",
  kr: "kr",
  cn: "cn",
  tw: "tw",
};

export const HTML_LANGUAGE_CODES_MAPPER = {
  [LANGUAGES.jp]: "ja",
  [LANGUAGES.us]: "en",
  [LANGUAGES.kr]: "ko",
  [LANGUAGES.cn]: "zh-Hans",
  [LANGUAGES.tw]: "zh-Hant",
};

export const ACCEPT_LANGUAGE_CODES_MAPPER = {
  [LANGUAGES.jp]: "ja-JP",
  [LANGUAGES.us]: "en-US",
  [LANGUAGES.kr]: "ko-KR",
  [LANGUAGES.cn]: "zh-CN",
  [LANGUAGES.tw]: "zh-TW",
};

export const LANGUAGES_SWITCH = [
  { name: "日本語", value: LANGUAGES.jp },
  { name: "English", value: LANGUAGES.us },
  { name: "한국어", value: LANGUAGES.kr },
  { name: "简体中文", value: LANGUAGES.cn },
  { name: "繁體中文", value: LANGUAGES.tw },
];
