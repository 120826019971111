import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./index.scss";
import { useLocalesMatch } from "@hooks";

const DeviceRotationNotify = () => {
  const [isOrientationLandscape, setIsOrientationLandscape] = useState(false);
  const selectStoreRouteMatch = useLocalesMatch("/store-selection");
  const selectCustomizeDrinkRouteMatch = useLocalesMatch("/customize-drink");

  useEffect(() => {
    const orientationChange = (_, isInitial = false) => {
      const kvContainerVideo: HTMLDivElement = document.querySelector(
        ".kv-container-video"
      );
      const isLandscapeMode = [90, -90].includes(+window.orientation);
      const mainContainer = document.getElementById("js-main-container");
      const inputs = document.querySelectorAll<HTMLElement>("input, textarea");
      const isIOSSafari =
        (/iP(ad|od|hone)/i.test(window.navigator.userAgent) ||
          (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
        !!navigator.userAgent.match(/Version\/.+Safari/);
      if (isLandscapeMode) {
        mainContainer &&
          mainContainer.classList.add("main-container-landscape");
        document.documentElement.classList.add("hidden-landscape");
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].blur();
        }
      } else {
        if (mainContainer) {
          mainContainer.classList.remove("main-container-landscape");
          if (!isInitial) {
            if (isIOSSafari) {
              mainContainer.classList.add("full-height");
              setTimeout(() => {
                setTimeout(() => {
                  mainContainer.classList.remove("full-height");
                }, 600);
              }, 0);
              if (kvContainerVideo) {
                setTimeout(() => {
                  const spMediaMatch = matchMedia("(max-width: 650px)").matches;
                  if (spMediaMatch) {
                    kvContainerVideo.style.position = "fixed";
                    setTimeout(() => {
                      kvContainerVideo.style.removeProperty("position");
                    }, 1000);
                  }
                }, 2000);
              }
            }
            // detect Realme device for bug fixed
            if (
              /RMX/i.test(navigator.userAgent) &&
              (selectStoreRouteMatch || selectCustomizeDrinkRouteMatch)
            ) {
              mainContainer.classList.add("fixed");
            }
          }
        }
        document.documentElement.classList.remove("hidden-landscape");
      }
      setIsOrientationLandscape(isLandscapeMode);
    };
    window.addEventListener("orientationchange", orientationChange);
    orientationChange(null, true);

    return () =>
      window.removeEventListener("orientationchange", orientationChange);
  }, []);

  return (
    <div
      className={`device-rotation-notify ${
        isOrientationLandscape ? "active" : ""
      }`.trim()}
    >
      <StaticImage
        className="device-rotation-notify-image"
        src="../../images/device-rotation-inform.png"
        alt="Device rotation notify"
      />
    </div>
  );
};

export default DeviceRotationNotify;
