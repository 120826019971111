import { Models } from "@rematch/core";
import { step } from "./step";
import { drink } from "./drink";
import { loading } from "./loading";
import { input } from "./input";
import { option } from "./option";
import { filter } from "./filter";
import { banner } from "./banner";
import { animation } from "./animation";
import { order } from "./order";
import { error } from "./error";
import { tagFont } from "./tagFont";
import { startOrderButton } from "./startOrderButton";
import { top } from "./top";

export type StepModel = typeof step.state;
export type DrinkModel = typeof drink.state;
export type LoadingModel = typeof loading.state;
export type InputModel = typeof input.state;
export type OptionModel = typeof option.state;
export type FilterModel = typeof filter.state;
export type SelectedFilterModel = typeof filter.state.selected;
export type BannerModel = typeof banner.state;
export type AnimationModel = typeof animation.state;
export type OrderModel = typeof order.state;
export type TagFontModel = typeof tagFont.state;
export type StartOrderButtonModel = typeof startOrderButton.state;
export type TopModal = typeof top.state;

export interface RootModel extends Models<RootModel> {
  step: typeof step;
  drink: typeof drink;
  loading: typeof loading;
  input: typeof input;
  option: typeof option;
  filter: typeof filter;
  banner: typeof banner;
  animation: typeof animation;
  order: typeof order;
  error: typeof error;
  tagFont: typeof tagFont;
  startOrderButton: typeof startOrderButton;
  top: typeof top;
}

export const models: RootModel = {
  step,
  drink,
  loading,
  input,
  option,
  filter,
  banner,
  animation,
  order,
  error,
  tagFont,
  startOrderButton,
  top,
};
