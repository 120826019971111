import { createModel } from "@rematch/core";
import { RootModel } from "./";

export const animation = createModel<RootModel>()({
  state: {
    runBannerAnimation: false,
    bannerAnimationDuration: 800,
    runSelectSelectedOptionAnimation: false,
    runDrinkSelectedOptionAnimation: false,
    runHotColdSelectedOptionAnimation: false,
    runEspressoSelectedOptionAnimation: false,
    runSweetSelectedOptionAnimation: false,
    runOptionSelectedOptionAnimation: false,
    runOptionImageSelectedOptionAnimation: false,
  },
  reducers: {
    setRunAnimation(state, payload) {
      return { ...state, ...payload };
    },
  },
});
