import { createModel } from "@rematch/core";

import { RootModel } from "./";
import { parseNavigateUrl } from "@hooks/useNavigateUrl";

export const error = createModel<RootModel>()({
  state: {
    pageContent: "",
    message: "",
  },
  reducers: {
    setErrorPageMessage(state, payload: any) {
      if (payload?.error) {
        state.pageContent = payload.error;
      }
      return state;
    },
  },
  effects: (dispatch) => ({
    showPage(payload, state) {
      if (!payload?.status && !payload?.error) {
        return;
      }

      dispatch.error.setErrorPageMessage(payload);
      const {
        top: { shopId, isInShopMode },
      } = state;

      const errorNavigateUrl = parseNavigateUrl("/error", {
        shopId,
        shopMode: !!isInShopMode || null,
      });

      payload?.navigate(errorNavigateUrl);
    },
  }),
});
