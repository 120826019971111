import React, {
  useMemo,
  useEffect,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { useCountUp } from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "@reach/router";
import { useI18next } from "gatsby-plugin-react-i18next";

import ProgressBar from "@components/ProgressBar";
import loadingLogo from "@images/loading/logo-large.svg";
import loadingLogoWinter from "@images/loading/logo-large-winter.svg";
import loading from "@images/loading/loading.gif";
import { useNavigateUrl, useLocationSearch } from "@hooks";
import { parseNavigateUrl } from "@hooks/useNavigateUrl";
import { detectIsSeasonDuring } from "@utils/commons";
import { LANGUAGES } from "@constants/global";

import "./index.scss";

const Loading = () => {
  const { language, navigate } = useI18next();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { shopMode: isInShopMode } = useLocationSearch();
  const [isWinterDuring, setIsWinterDuring] = useState(false);
  // loading
  const loadingData = useSelector((state) => state.loading);
  const orderData = useSelector((state) => state.order.data);
  const getTicketNavigateUrl = useNavigateUrl("/get-ticket");
  const preOrderNavigateUrl = useNavigateUrl("/pre-order");
  const setIsLoading = useCallback(
    (value) => dispatch.loading.setIsLoading(value),
    [dispatch]
  );
  const { countUp, start, update, reset } = useCountUp({
    start: 0,
    end: 80,
    duration: loadingData?.duration - 1,
    startOnMount: false,
  });

  useLayoutEffect(() => {
    if (!loadingData.isLoading) return;

    reset();
    start();
  }, [loadingData.isLoading]);

  useEffect(() => {
    if (!loadingData.isLoading) return;
    if (!orderData) return;

    const navigateUrl = isInShopMode
      ? getTicketNavigateUrl
      : preOrderNavigateUrl;
    navigate(
      parseNavigateUrl(navigateUrl, {
        qrHash: orderData?.qrHash,
        ...(isInShopMode || language !== LANGUAGES.jp
          ? {}
          : { lang: language }),
      })
    );
  }, [loadingData.isLoading, orderData]);

  useEffect(() => {
    if (isInShopMode) {
      const interval = setInterval(() => {
        if (loadingData.isFinished) {
          update(100);
          const timer = setTimeout(() => {
            setIsLoading(false);
            clearTimeout(timer);
          }, 800);
        }
      }, loadingData?.duration * 1000);
      return () => clearInterval(interval);
    } else {
      if (loadingData.isFinished && loadingData.isLoading) {
        update(100);
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      }
    }
  }, [loadingData, isInShopMode]);

  useEffect(() => {
    // prettier-ignore
    detectIsSeasonDuring([
      [process.env.GATSBY_WINTER_START, process.env.GATSBY_WINTER_END, setIsWinterDuring]
    ]);
  }, [pathname]);

  return useMemo(
    () =>
      loadingData.isLoading ? (
        <div
          className={`loading-container ${
            isInShopMode ? "loading-container-shop-mode" : ""
          }`}
        >
          <div
            className={`${isInShopMode ? "" : "wrapper"} ${
              loadingData.isFinished ? "rotate" : ""
            } ${isWinterDuring && !isInShopMode ? "wrapper-winter" : ""}`}
          >
            <div
              className={`bottle-list ${
                isWinterDuring && !isInShopMode ? "winter" : ""
              }`}
            >
              <img
                src={
                  isInShopMode
                    ? loading
                    : isWinterDuring
                    ? loadingLogoWinter
                    : loadingLogo
                }
                alt="loading"
              />
            </div>
            <div
              className={`progress-bar-container loading-progress ${
                isWinterDuring && !isInShopMode
                  ? "progress-bar-container-winter"
                  : ""
              }`}
            >
              <ProgressBar width={`${countUp}%`} />
            </div>
          </div>
        </div>
      ) : null,
    [countUp, loadingData, isInShopMode, isWinterDuring]
  );
};

export default Loading;
