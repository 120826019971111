import snakecaseKeys from "snakecase-keys";

import http from "../utils/http";
import { parseNavigateUrl } from "../hooks/useNavigateUrl";

export const getDrinks = (shopId: unknown) => {
  const url = parseNavigateUrl("/drinks", { shop_id: shopId ?? null });
  return http.get(url);
};

export const getLabelTemplate = (shopId: unknown) => {
  const url = parseNavigateUrl("/design-templates", {
    shop_id: shopId ?? null,
  });
  return http.get(url);
};

export const getBanners = (shopId: unknown) => {
  const url = parseNavigateUrl("/banners", { shop_id: shopId ?? null });
  return http.get(url);
};

export const getQrCode = (qrHash: string, isInShopMode = false) => {
  const url = parseNavigateUrl(`/orders/${qrHash}`, {
    shop_mode: !!isInShopMode || null,
  });
  return http.get(url);
};

export const postOrder = (data: {
  drinkId: number;
  designTemplateId: number;
  bannerId: number;
  optionIds: any;
  labelText_1: string;
  labelText_2: string;
  filterLabels: any;
}) => {
  return http.post("/order", snakecaseKeys(data));
};

export const getShops = () => {
  return http.get("shops", {
    headers: {
      "Accept-Version": "v2"
    }
  });
};

export const getShop = (shopId: unknown) => {
  return http.get(`shops/${shopId}`);
};

export const getNews = () => {
  return http.get("news");
};

export const getLimitedDesigns = () => {
  return http.get("limited-designs");
};
