import { createModel } from "@rematch/core";
import { RootModel } from "./";

const initInputData = {
  validationError: {
    text1: null,
    text2: null,
  },
  value: {
    text1: "",
    text2: "",
  },
  isShowModal: false,
};

export const input = createModel<RootModel>()({
  state: initInputData,
  reducers: {
    setValidationError(state, payload) {
      state.validationError = payload
        ? { ...state.validationError, ...payload }
        : {};

      return state;
    },
    setValue(state, payload) {
      state.value = { ...state.value, ...payload };
      return state;
    },
    setIsShowModal(state, payload) {
      state.isShowModal = payload;
      return state;
    },
    resetData(state) {
      return { ...state, ...initInputData };
    },
  },
  effects: (dispatch) => ({
    setInputValue({ name, value, labelType }) {
      dispatch.input.setValue({ [name]: value });
      dispatch.tagFont.setTextTagFont({ name, value, labelType });
    },
  }),
});
