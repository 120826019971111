import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import selectorsPlugin from "@rematch/select";
import immerPlugin from "@rematch/immer";
import updatedPlugin from "@rematch/updated";

import { models, RootModel } from "./";

export const store = init<RootModel>({
  models,
  plugins: [selectorsPlugin(), immerPlugin(), updatedPlugin()],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
