import { createModel } from "@rematch/core";
import { RootModel } from "./";

export const order = createModel<RootModel>()({
  state: {
    data: null,
    labelImage: "",
  },
  reducers: {
    setData(state, payload) {
      state.data = payload;
      state.data.qrImage = "data:image/png;base64," + state.data.qrImage;

      return state;
    },
    setLabelImage(state, payload) {
      state.labelImage = payload;

      return state;
    },
    resetData(state) {
      state.data = null;
      state.labelImage = "";

      return state;
    },
  },
});
