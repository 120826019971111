import React from "react";
import { Provider } from "react-redux";
import { globalHistory } from "@reach/router";
import "intersection-observer";

import { store } from "./src/models/store";
import DeviceRotationNotify from "./src/components/DeviceRotationNotify";

export const onInitialClientRender = () => {
  globalHistory._onTransitionComplete();
};

export const onClientEntry = () => {
  const storageLang = localStorage.getItem("gatsby-i18next-language");
  if (!storageLang) {
    const browserLanguage =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage ||
      window.navigator.systemLanguage ||
      null;
    const browserLanguageLowerCase = browserLanguage.toLocaleLowerCase();
    if (browserLanguage.startsWith("ko")) {
      localStorage.setItem("gatsby-i18next-language", "kr");
    } else if (
      browserLanguageLowerCase.startsWith("zh-tw") ||
      browserLanguageLowerCase.startsWith("zh-hant") ||
      browserLanguageLowerCase.startsWith("zh-cht") ||
      browserLanguageLowerCase.startsWith("zh-cmn-hant") ||
      browserLanguageLowerCase.startsWith("zh-cmn-hant-tw") ||
      browserLanguageLowerCase.startsWith("zh-hant-cn") ||
      browserLanguageLowerCase.startsWith("zh-hant-tw")
    ) {
      localStorage.setItem("gatsby-i18next-language", "tw");
    } else if (
      browserLanguageLowerCase.startsWith("zh-cn") ||
      browserLanguageLowerCase.startsWith("zh-hans") ||
      browserLanguageLowerCase.startsWith("zh-chs") ||
      browserLanguageLowerCase.startsWith("zh-hans-cn") ||
      browserLanguageLowerCase.startsWith("zh-cmn-hans") ||
      browserLanguageLowerCase.startsWith("zh-cmn-hans-cn")
    ) {
      localStorage.setItem("gatsby-i18next-language", "cn");
    }
  }
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <DeviceRotationNotify />
    </>
  );
};

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
