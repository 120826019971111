import { createModel } from "@rematch/core";
import camelcaseKeys from "camelcase-keys";
import { captureException } from "@sentry/gatsby";

import {
  steps,
  animation,
  allPlaceholder,
  labelTemplateName,
  keyMapping,
  hotColdBackgroundImage,
  hotColdAnimationImages,
  espressoAnimationImages,
  DRINK_CATEGORIES,
} from "@constants/global";
import { getDrinks, getBanners, getLabelTemplate, getShop } from "@api";
import { sortByPriority } from "./filter";

const setStepBar = (state, payload) => {
  const array = ["espresso", "hotCold", "sweet", "option"];
  array.forEach((optionKey) => {
    const localValue = state.find((item) => item.value === optionKey);
    const apiValue = payload.find((item) => item.value === optionKey);
    if (localValue) {
      localValue.name = apiValue?.name || localValue.name;
      localValue.isHidden = !apiValue;
    }
  });
};

const getLabelTemplatesAsync = async function (shopId: unknown) {
  const data: any = await getLabelTemplate(shopId);
  const camelcaseData = camelcaseKeys(data, { deep: true });
  return {
    designs: camelcaseData.designs?.map((item) => {
      return {
        id: item.id,
        value: item.value,
        displayValue: labelTemplateName[item.value],
        name: `${item.name} (${labelTemplateName[item.value]})`,
        type: "label",
        price: item.designTemplatePrice,
        animationRunningMilliseconds: animation.labelTemplate.milliseconds,
        htmlUrl: item.htmlUrl,
        imageUrl: item.imageUrl,
        limitedIcon: item.limitedIcon,
        isStoreLimited: item.isStoreLimited,
        isSeasonalLimited: item.isSeasonalLimited,
        isLimited: !!item.isLimited,
      };
    }),
    inputTexts: camelcaseData.inputTexts?.map((value, key) => {
      return {
        id: key,
        text: value,
      };
    }),
  };
};

const getDrinksAsync = async function (shopId: unknown) {
  const data: any = await getDrinks(shopId);

  return camelcaseKeys(data, { deep: true }).categories?.map((category) => {
    keyMapping["select"].forEach((drinkMapping) => {
      const { localKey, staticValue } = drinkMapping;
      category[localKey] = staticValue;
    });
    category["selectAnimationImage"] = DRINK_CATEGORIES.find(
      (item) => item.value === category.pattern
    ).animation;

    return {
      ...category,
      value: category.label,
      type: "select",
      drinks: category?.drinks?.map((item) => {
        keyMapping["drink"].forEach((drinkMapping) => {
          const { localKey, apiKey, staticValue } = drinkMapping;
          item[localKey] = staticValue || item[apiKey];
        });

        return {
          ...item,
          value: item.label,
          type: "drink",
          drinkCustomizes: item.drinkCustomizes.map((customizeItem) => ({
            type: "customizeDrink",
            name: customizeItem.name,
            value: customizeItem.value,
            options: customizeItem.drinkCustomizeOptions.map(
              (customizeOptionItem) => {
                keyMapping[customizeItem.value]?.map((optionMapping) => {
                  const { localKey, apiKey, staticValue } = optionMapping;
                  customizeOptionItem[localKey] =
                    staticValue || customizeOptionItem[apiKey];

                  if (customizeItem.value === "espresso") {
                    customizeOptionItem["espressoAnimationImages"] = {
                      [item.label]: espressoAnimationImages[
                        customizeOptionItem["animation"]
                      ]
                        ? [
                            espressoAnimationImages[
                              customizeOptionItem["animation"]
                            ],
                          ]
                        : [],
                    };
                  }
                  if (customizeItem.value === "hotCold") {
                    customizeOptionItem["backgroundImage"] =
                      hotColdBackgroundImage[customizeOptionItem["animation"]];
                    const espressoCustomize = item.drinkCustomizes.find(
                      (objItem) => objItem.value === "espresso"
                    );
                    espressoCustomize?.drinkCustomizeOptions?.forEach(
                      (option) => {
                        customizeOptionItem["hotColdAnimationImages"] = {
                          ...customizeOptionItem["hotColdAnimationImages"],
                          [option.optionLabel]:
                            hotColdAnimationImages[option.animation] ||
                            hotColdAnimationImages[
                              customizeOptionItem["animation"]
                            ] ||
                            [],
                        };
                      }
                    );
                  }
                  if (customizeItem.value === "sweet") {
                    const espressoCustomize = item.drinkCustomizes.find(
                      (objItem) => objItem.value === "espresso"
                    );
                    if (espressoCustomize) {
                      const sweetAnimationImages =
                        espressoCustomize?.drinkCustomizeOptions?.reduce(
                          (previous, next) => {
                            previous[next.optionLabel] =
                              espressoAnimationImages[next["animation"]]
                                ? [
                                    `${
                                      espressoAnimationImages[
                                        next["animation"]
                                      ]?.split(".")[0]
                                    }-sweet.gif`,
                                  ]
                                : [
                                    `${
                                      item["animation"]?.split(".")[0]
                                    }-sweet.gif`,
                                  ];
                            return previous;
                          },
                          {}
                        );
                      customizeOptionItem["sweetAnimationImages"] =
                        customizeOptionItem["animation"]
                          ? sweetAnimationImages
                          : {};
                    }
                  }
                });

                return {
                  ...customizeOptionItem,
                  type: customizeItem.value,
                  name: customizeOptionItem.optionName,
                  value: customizeOptionItem.optionLabel,
                  price: customizeOptionItem.optionPrice ?? null,
                  isNew: !!customizeOptionItem.isNew,
                };
              }
            ),
          })),
        };
      }),
    };
  });
};

const getBannersAsync = async function (shopId: unknown) {
  const data: any = await getBanners(shopId);
  const formattedData = camelcaseKeys(data, { deep: true });

  return {
    filters: {
      categoryType: [
        ...sortByPriority(
          formattedData.categoryTypeFilters.map((item) => {
            return {
              id: item.id,
              name: item.filterName,
              count: item.count,
              displayPriority: item.displayPriority,
            };
          })
        ),
        {
          id: 0,
          name: allPlaceholder.halfWidth,
        },
      ],
      color: [
        { id: 0, name: "COLOR" },
        ...sortByPriority(
          formattedData.colorFilters.map((item) => ({
            id: item.id,
            name: item.filterName,
            displayPriority: item.displayPriority,
          }))
        ),
      ],
    },
    banners: formattedData.banners,
  };
};

const initStepData = {
  baseData: [...steps],
  data: [...steps],
  active: {
    id: 1,
    value:
      steps[0].type === "customizeLabel"
        ? steps[0].steps[0].value
        : steps[0].value,
    name:
      steps[0].type === "customizeLabel"
        ? steps[0].steps[0].name
        : steps[0].name,
  },
  isSelected: {
    drink: false,
    hotCold: false,
    espresso: false,
    sweet: false,
    option: false,
    label: false,
    banner: false,
    text: false,
  },
  isShowBar: true,
  isShowLogo: false,
  isStepBarWithAnimation: false,
  dataInputTexts: [],
};

export const step = createModel()({
  state: initStepData,
  reducers: {
    setData(state, payload) {
      state.data = payload;

      return state;
    },
    setBaseData(state, payload) {
      state.baseData.find((item) => item.value === "select").options = payload;

      return state;
    },
    setDrinkSelected(state, payload) {
      const { drinks, isDataChanged } = payload;
      if (isDataChanged) {
        state.data.find((item) => item.value === "drink").options = drinks;
        ["espresso", "hotCold", "sweet", "option"].forEach((item) => {
          const optionItem = state.data.find(
            (stepItem) => stepItem.value === item
          );
          if (optionItem) {
            optionItem.options = [];
          }
        });
        setStepBar(state.data, drinks?.[0]?.drinkCustomizes || []);
      }

      return state;
    },
    setSelectsData(state, payload) {
      state.data.find((item) => item.value === "select").options =
        payload.selects;

      setStepBar(
        state.data,
        payload?.currentDrink?.drinkCustomizes ||
          payload.selects[0]?.drinks?.[0]?.drinkCustomizes
      );

      return state;
    },
    setLabelTemplatesData(state, payload) {
      state.data.find(
        (item) => item.type === "customizeLabel"
      ).steps[0].options = payload;
      state.baseData = state.data;

      return state;
    },
    setStepBarWithAnimation(state, payload: boolean) {
      state.isStepBarWithAnimation = payload;

      return state;
    },
    setSelectedStep(state, payload: string) {
      state.isSelected[payload] = true;

      return state;
    },
    setSelectedStatus(state, payload) {
      state.isSelected = {
        ...state.isSelected,
        ...payload,
      };

      return state;
    },
    setActive(state, payload) {
      state.active = payload;

      return state;
    },
    setToggleBar(state, payload) {
      state.isShowBar = payload;

      return state;
    },
    setToggleLogo(state, payload) {
      state.isShowLogo = payload;

      return state;
    },
    setInputTexts(state, payload) {
      state.dataInputTexts = payload;

      return state;
    },
    resetData(state) {
      return { ...state, ...initStepData };
    },
  },
  effects: (dispatch) => ({
    async getMasterDataAsync({ shopId, navigate }, state) {
      if (shopId !== undefined) {
        getShop(shopId)
          .then((response) => {
            const formattedData: any = camelcaseKeys(response, { deep: true });
            dispatch.drink.setLabelValue({
              shop: {
                id: shopId,
                name: formattedData?.label,
                cardLabel: formattedData?.cardLabel,
              },
            });
          })
          .catch((error) => {
            captureException(new Error(error?.error || error));
            dispatch.error.showPage({ ...error, navigate });
          });
      }

      try {
        const selects = await getDrinksAsync(shopId);
        dispatch.step.setSelectsData({
          selects,
          currentDrink: state.drink.drink,
        });
        dispatch.step.setBaseData(selects);
        dispatch.step.setStepBarWithAnimation(true);
        dispatch.loading.setIsFetchingDrinksAPI(false);
      } catch (error) {
        captureException(new Error(error?.error || error));
        dispatch.error.showPage({ ...error, navigate });
        dispatch.loading.setIsFetchingDrinksAPI(false);
      }

      try {
        const labelTemplates = await getLabelTemplatesAsync(shopId);
        dispatch.step.setLabelTemplatesData(labelTemplates.designs);
        dispatch.step.setInputTexts(labelTemplates.inputTexts);
      } catch (error) {
        captureException(new Error(error?.error || error));
        dispatch.error.showPage({ ...error, navigate });
      }

      try {
        const { filters, banners } = await getBannersAsync(shopId);
        const selectedFilter = state.filter.selected;

        const initSelectedFilterValue = {
          categoryType: selectedFilter.categoryType || filters.categoryType[0],
          color: selectedFilter.color || filters.color[0],
        };

        dispatch.filter.setData(filters);
        dispatch.filter.setSelectedValue(initSelectedFilterValue);

        dispatch.drink.setLabelValue({
          filters: initSelectedFilterValue,
        });

        dispatch.banner.setData(banners);
        dispatch.banner.setFilteredData(initSelectedFilterValue);
      } catch (error) {
        captureException(new Error(error?.error || error));
        dispatch.error.showPage({ ...error, navigate });
      }
    },
    setCustomizeDrinkElement(payload, state) {
      const data = JSON.parse(JSON.stringify(state.step.data));

      payload.map((item) => {
        const optionItem = data.find(
          (stepItem) => stepItem.value === item.value
        );
        if (optionItem) {
          optionItem.options = item.options;
        }
      });
      setStepBar(data, payload);
      dispatch.step.setData(data);
    },
  }),
});
