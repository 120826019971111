import { createModel } from "@rematch/core";
import { RootModel } from "./";

export const startOrderButton = createModel<RootModel>()({
  state: {
    isFixed: true,
  },
  reducers: {
    setData(state, payload) {
      state.isFixed = payload;

      return state;
    },
  },
});
