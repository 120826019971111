import { createModel } from "@rematch/core";

export const sortByPriority = (data: any[], keyMapped: string = "") =>
  data.sort((item1, item2) => {
    let item1DisplayPriority =
      item1?.bannerOption?.displayPriority?.[keyMapped] || null;
    let item2DisplayPriority =
      item2?.bannerOption?.displayPriority?.[keyMapped] || null;

    if (!item1DisplayPriority && !item2DisplayPriority) {
      item1DisplayPriority = item1?.displayPriority;
      item2DisplayPriority = item2?.displayPriority;
    }

    return item2DisplayPriority === item1DisplayPriority
      ? item2.id - item1.id
      : item1DisplayPriority === null
      ? 1
      : item2DisplayPriority === null
      ? -1
      : item2DisplayPriority - item1DisplayPriority;
  });

const initFilterData = {
  selected: {
    categoryType: null,
    color: null,
  },
  data: {
    categoryType: [],
    color: [],
  },
  filteredData: {
    categoryType: [],
    color: [],
  },
};

export const filter = createModel()({
  state: initFilterData,
  reducers: {
    setData(state, payload) {
      state.data = payload;
      state.filteredData = payload;
      return state;
    },
    setSelectedValue(state, payload: any) {
      return {
        ...state,
        selected: { ...state.selected, ...payload },
      };
    },
    resetData(state) {
      return { ...state, ...initFilterData };
    },
  },
  effects: (dispatch) => ({
    async setSelectedFilterValue(payload, state) {
      dispatch.filter.setSelectedValue(payload);
      dispatch.banner.setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      dispatch.banner.setFilteredData({ ...state.filter.selected, ...payload });
      await new Promise((resolve) => setTimeout(resolve, 400));
      dispatch.banner.setIsLoading(false);
    },
  }),
});
