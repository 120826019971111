import { createModel } from "@rematch/core";
import camelcaseKeys from "camelcase-keys";
import { captureException } from "@sentry/gatsby";

import { RootModel } from ".";
import { postOrder } from "@api";
import {
  textDefaultValues,
  allPlaceholder,
  emptyPlaceholder,
  shopNameDefault,
} from "@constants/global";

const initDrinkData = {
  drinkAnimationImage: null,
  selectAnimationImage: null,
  hotColdAnimationImages: null,
  espressoAnimationImages: null,
  sweetAnimationImages: null,
  animationRunningMilliseconds: 0,
  select: null,
  drink: null,
  hotCold: null, // optionIds - hotCold
  sweet: null, // optionIds - sweet
  option: null, // optionIds - flavor
  espresso: null, // optionIds - body
  label: null,
  filters: null, // filterLabels
  banner: null,
  text1: null,
  text2: null,
  shop: {
    id: null,
    name: shopNameDefault.previewLabel,
  },
  createdAt: "2021.09.16 08:34:57",
  isBlur: false,
  drinkImageIsStatic: false,
  showLabelTemplateSlide: false,
  orderId: "001",
  isEspressoNotSelect: true,
};

export const drink = createModel<RootModel>()({
  state: initDrinkData,
  reducers: {
    setLabelValue(state, payload) {
      return { ...state, ...payload };
    },
    resetData(state) {
      return { ...state, ...initDrinkData };
    },
    setIsEspressoNotSelect(state, payload) {
      state.isEspressoNotSelect = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    async postDrinkDataAsync(payload, state) {
      const filterLabels = [];
      const {
        top: { shopId, isInShopMode },
      } = state;

      Object.keys(payload.filters || {}).forEach((key) => {
        if (payload.filters[key]?.id || payload.filters[key].name === "COLOR") {
          filterLabels.push(payload.filters[key].name);
        } else {
          filterLabels.push(allPlaceholder.halfWidth);
        }
      });
      filterLabels.splice(1, 0, emptyPlaceholder.halfWidth);

      const optionIds = [];

      if (payload.hotCold?.id) {
        optionIds.push(payload.hotCold?.id);
      }

      if (payload.espresso?.id) {
        optionIds.push(payload.espresso?.id);
      }

      if (payload.option?.id) {
        optionIds.push(payload.option?.id);
      }

      if (payload.sweet?.id) {
        optionIds.push(payload.sweet?.id);
      }

      dispatch.loading.setIsLoading(true);

      try {
        const requestData = {
          drinkId: payload.drink?.id,
          labelText_1: payload.text1 || textDefaultValues.text1,
          labelText_2: payload.text2 || textDefaultValues.text2,
          bannerId: payload.banner?.id,
          designTemplateId: payload.label?.id,
          filterLabels,
          optionIds,
          shopId: shopId ?? null,
          shopMode: !!isInShopMode,
        };
        const data = await postOrder(requestData);
        dispatch.order.setData(camelcaseKeys(data));
        if (!isInShopMode && shopId) {
          localStorage.setItem("currentShopId", shopId);
        }
      } catch (error) {
        captureException(new Error(error?.error || error));
        dispatch.error.showPage({ ...error, navigate: payload?.navigate });
      }
    },
    resetOrderData() {
      dispatch.drink.resetData();
      dispatch.step.resetData();
      dispatch.input.resetData();
      dispatch.filter.resetData();
      dispatch.banner.resetData();
      dispatch.loading.resetLoading();
      dispatch.order.resetData();
    },
  }),
});
