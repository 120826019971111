import React, { useMemo } from "react";

import "./index.scss";

const ProgressBar = ({ width }) => {
  return useMemo(
    () => (
      <div className="progress-bar">
        <div className="back"></div>
        <div
          className="front"
          style={{
            width,
          }}
        ></div>
      </div>
    ),
    [width]
  );
};
export default ProgressBar;
