import { useMemo } from "react";
import querystring from "query-string";

import useLocationSearch from "./useLocationSearch";

export default function useNavigateUrl(url: string, overrideParams = {}) {
  const { shopMode: isInShopMode, shopId } = useLocationSearch();
  const query = {
    shopMode: !!isInShopMode || null,
    ...(url === "/" && !isInShopMode ? {} : { shopId: shopId ?? null }),
    ...overrideParams,
  };

  const navigateUrl = useMemo(() => parseNavigateUrl(url, query), [url, query]);

  return navigateUrl;
}

export function parseNavigateUrl(url: string, query: Record<string, any>) {
  const { stringifyUrl } = querystring;
  return stringifyUrl(
    { url, query },
    { skipNull: true, skipEmptyString: true }
  );
}
