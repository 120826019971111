import { useState, useLayoutEffect } from "react";

export default function useRunAnimation(key, milliseconds, dependency = null) {
  const [runAnimation, setRunAnimation] = useState({
    drink: false,
    hotCold: false,
    espresso: false,
    sweet: false,
    espressoBackground: false,
  });

  useLayoutEffect(() => {
    if (milliseconds && key) {
      setRunAnimation((prevState) => ({ ...prevState, [key]: true }));
      const timmer = setTimeout(() => {
        setRunAnimation((prevState) => ({ ...prevState, [key]: false }));
      }, milliseconds);

      return () => clearTimeout(timmer);
    }
  }, [key, dependency]);

  return runAnimation;
}
