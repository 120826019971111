import axios from "axios";

const instance = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    "X-API-Key": process.env.GATSBY_API_KEY,
  },
});

export const handleError = (response: Response & { data: any }) => {
  return {
    status: response?.status,
    error: response?.data.message,
  };
};

export default instance;
