import { useMatch } from "@reach/router";
import { useI18next } from "gatsby-plugin-react-i18next";

export default function useLocalesMatch(url: string) {
  const { language } = useI18next();
  const defaultMatcher = useMatch(url);
  const localesMatcher = useMatch(`/${language}${url}`);

  return defaultMatcher || localesMatcher;
}
